<template>
  <div class="page">
    <div class="c-page o-page-wrapper c-page_achievement">
      <Sidebar :status="'about'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content">
          <Content></Content>
          <MilestoneList></MilestoneList>
          <LikeSuggestion></LikeSuggestion>
          <GetStartedContent></GetStartedContent>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import Content from '@/components/about/MilestoneArchievementContent.vue'
import MilestoneList from '@/components/about/Milestone.vue'
import GetStartedContent from '@/components/GetStartedContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'

export default {
  name: 'Milestone',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    Content,
    MilestoneList,
    GetStartedContent,
    LikeSuggestion
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner/Bespoke Wealth.jpg'),
          title:"<span class='c-page-banner__bespoke-title'>Bespoke Digital <br /> Asset Solutions</span>",
          subtitle:"<span class='c-page-banner__bespoke-subtitle'>To achieve your growth objectives</span>",
          buttonText:"Get started >",
          buttonPage:"/institutional/wealth"
        },
      ],
      theme: 'two',
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Reputable Business Milestones and Achievements')
    this.updateDescriptionMeta('Sparrow has a proud track record of delivering high-quality, optimized, secure, and compliant digital asset solutions for institutions and accredited clients.')
    this.updateKeywordMeta('Sparrow, Sparrow Exchange, wealth management, Singapore cryptocurrencies, options trading platform')
  }
}
</script>
